import React from 'react'
import SiteLayout from '../components/SiteLayout'
import { Row } from 'antd'

import technicalDocumentation from '../images/technical-documentation-img.png'
import apiReference from '../images/api-reference-img.png'
import uiFframework from '../images/ui-framework-img.png'

import technicalDocumentation_Icon from "../images/technical-documentation-icon.png"
import apiReference_Icon from "../images/api-reference-icon.png"
import uiFframework_Icon from "../images/ui-framework-icon.png"

const GetStarted = ({ location }) => (
  <SiteLayout location={location}>
    <Row type="flex" justify="center">
      <div style={{ marginTop: 'calc( 3vh )' }} className="flex-container">
        <div className="flex-item">
          <h1 style={{ textAlign: 'center' }}>Welcome to the Strands Developer</h1>
          <h2 style={{ textAlign: 'center' }} className="small">The entry point to all technical documentation that one may need to<br></br> understand, install and operate the Strands Finance Suite</h2>
        </div>
      </div>
    </Row>
    <Row type="flex">
      <div style={{ marginTop: 'calc( 3vh )' }} className="flex-container">

        <div className="flex-item img-container">
          <img src={technicalDocumentation} alt="Get Started Image" className="float-image" />
        </div>

        <div className="flex-item">
          <img src={technicalDocumentation_Icon} className="title-icon" alt="Technical documentation icon" />
          <h2 className="title">Technical documentation</h2>
          <p>In here, you will find both functional and technical information about all the componentes in Strands FM: what does each widget do, how to install the database model, what's the format of the RTE queues to load data in real time, etc.</p>
        </div>

      </div>
    </Row>

    <Row type="flex">
      <div style={{ marginTop: 'calc( 3vh )' }} className="flex-container">

        <div className="flex-item">
          <img src={apiReference_Icon} className="title-icon" alt="API Reference icon" />
          <h2 className="title">API Reference</h2>
          <p>The API Reference section contains an interactive interface to visualize and interact with the API's resources. Its easy to use interface based on our OpenAPI specification should make using as straightforward as it can be.</p>
        </div>

        <div className="flex-item img-container">
          <img src={apiReference} alt="Get Started Image" className="float-image" />
        </div>

      </div>
    </Row>

    <Row type="flex">
      <div style={{ marginTop: 'calc( 3vh )' }} className="flex-container">

        <div className="flex-item img-container">
          <img src={uiFframework} alt="Get Started Image" className="float-image" />
        </div>

        <div className="flex-item">
          <img src={uiFframework_Icon} className="title-icon" alt="UI Framework icon" />
          <h2 className="title">UI Framework</h2>
          <p>The Finance-UI Kit documentation at the left will give you an overview of how the framework is built and how to customize it, but if you want to know how each component is built, used and configured, interact with them in realtime and see tons of examples, you'll find all the details in this section.</p>
        </div>

      </div>

    </Row>
    <h2 style={{ textAlign: 'center' }} className="small">Ready to build powerful products?</h2>
    <p style={{ textAlign: 'center' }}> Create a developer account, explore, and begin<br></br> integrating our APIs with a few lines of code.</p>

    <p style={{ textAlign: 'center' }} ><a href="/become-partner" className="btn">Become a Partner</a></p>
  </SiteLayout>
)

export default GetStarted
